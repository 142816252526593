import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import Header from "./Header";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Form from "react-bootstrap/Form";
import CryptoJS from "crypto-js";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import moment from "moment";
import Dropzone from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// const steps = ["Personal Detail", "Contact Detail", "Confirm"];
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function Home() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [pagestep, setpagestep] = React.useState(0);

  const [Mobile, setMobile] = useState();
  const [ProfileImg, setProfileImg] = useState();
  const [OTP, setOTP] = useState();
  const [showOTP, setShowOTP] = useState(false);
  const [isSendOTPDisabled, setIsSendOTPDisabled] = useState(false);
  const [id, setid] = useState();
  const [NewUser, setNewUser] = useState();
  // const isStepOptional = (step) => {
  //   return step === 1;
  // };

  // const isStepSkipped = (step) => {
  //   return skipped.has(step);
  // };
  // const handleNext = () => {
  //   let newSkipped = skipped;
  //   setpagestep(parseInt(pagestep) + 1);
  //   if (isStepSkipped(activeStep)) {
  //     newSkipped = new Set(newSkipped.values());
  //     newSkipped.delete(activeStep);
  //   }
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped(newSkipped);
  // };
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [timer, setTimer] = useState(30);
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      //setTimer(30);
      setIsResendDisabled(false);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const startResendTimer = () => {
    setTimer(30); // Reset timer to 2 minutes
    setIsResendDisabled(true);
  };

  const handleBack = () => {
    // setpagestep("0");
    setpagestep(parseInt(pagestep) - 1);
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleBackHome = () => {
    // setpagestep("0");
    setOTP("");
    setShowOTP(false);
    setIsSendOTPDisabled(false);
    setpagestep(0);
    //  setActiveStep((prevActiveStep) => 0);
  };
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [AuthkeyTEXT, setAuthkeyTEXT] = useState(
    "/jkDbQh90RDth/IX1D+tXAA57uWG4h+zkEN/cl51W8xvmEpEI/vNNmsbJFNwdWiNvD7RqW7vqoGkh8pVdTFOZRAvqiDD4itkay8GbuUKecA="
  );

  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`.replace(/'/g, "");

  const mobileSendOtp = (otp) => {
    // setOTP(otp)
    // return
    if (Mobile === "") {
      alert("Please enter MobileNo");
      return;
    } else {
      const SMSPra = {
        senderID: "DALERP",
        dltEntityID: "1201159187215982418",
        mobile: Mobile,
        smsContent: `<%23> Otp for Canwinn Saccha Sathi App is ${otp}. DIALERP TEAM Z/64RuUKfHG`,
        templateID: "1007822973245487897",
        parentID: "40119",
      };
      //alert(4);
      fetch(baseURL + "/api/AppApi/SendSMSOTP", {
        method: "POST",
        headers: {
          // "Authorization-Token": AuthkeyTEXT,
          "Authorization-Token": authToken,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(SMSPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp?.hasErrors === false) {
            // DecryptN1(resp.response);
          } else {
            alert("SMS Invalid request!!.");
            return;
          }
        });
      });
    }
  };

  const key = "0e628f15575e4a71"; //"0e628f15575e4a7189ec11a50301bb70";
  const DecryptN1 = (encryptedText) => {
    const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedText);
    const decryptedHex = CryptoJS.AES.decrypt(
      { ciphertext: encryptedBytes },
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(key),
        keySize: 128,
        blockSize: 128,
      }
    );
    const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedHex);
    if (
      decryptedText !== undefined &&
      decryptedText !== "undefined" &&
      decryptedText !== "" &&
      decryptedText !== null
    ) {
      // setOTP(decryptedText);
      mobileSendOtp(decryptedText);
    } else {
      DecryptN1(encryptedText);
    }
  };
  const mobileSendOtpMongo = () => {
    if (Mobile === "") {
      alert("Enter Mobile Number");
      return;
    } else {
      setIsResendDisabled(true);
      setTimer(30);
      const loginPra = {
        baseTokenKey: AuthkeyTEXT,
        resend: "0",
        mobile: Mobile,
      };
      fetch(baseURL + "/api/DynamicApp/mobileSendOtpMongo", {
        method: "POST",
        headers: {
          // "Authorization-Token": AuthkeyTEXT,
          Authkey: AuthkeyTEXT,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp?.hasErrors === false) {
            setShowOTP(true);
            setIsSendOTPDisabled(true);
            DecryptN1(resp.response);
          } else {
            alert("Invalid request!!.");
            return;
          }
        });
      });
    }
  };
  function verifyMobileSendOtp() {
    if (OTP === "" || OTP === null || OTP === undefined) {
      alert("Enter OTP");
      return;
    } else {
      const checkMobileUserPra = {
        collectionNumber: "1",
        mobile: Mobile,
        otp: OTP,
        type: "12",
      };
      fetch(baseURL + "/api/DynamicApp/verifyMobileSendOtp", {
        method: "POST",
        headers: {
          // "Authorization-Token": AuthkeyTEXT,
          Authkey: AuthkeyTEXT,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(checkMobileUserPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            console.log(resp);
            setpagestep(1);
          } else {
            alert(resp.errors);
          }
        });
      });
    }
  }
  function mobileSendOtpNew() {
    setIsResendDisabled(true);
    setTimer(30);
    const checkMobileUserPra = {
      resend: "0",
      collectionNumber: "1",
      mobile: Mobile,
      type: "12",
    };
    fetch(baseURL + "/api/DynamicApp/mobileSendOtpNew", {
      method: "POST",
      headers: {
        // "Authorization-Token": AuthkeyTEXT,
        Authkey: AuthkeyTEXT,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(checkMobileUserPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          setShowOTP(true);
          setIsSendOTPDisabled(true);
          mobileSendOtp(resp.response.otp);
          // mobileSendOtp()
          // setShowOTP(true);
          // setIsSendOTPDisabled(true)
          // mobileSendOtpMongo();
        } else {
          alert(resp.errors);
        }
      });
    });
  }
  function checkMobileUserMongoDynamic() {
    const checkMobileUserPra = {
      baseTokenKey: AuthkeyTEXT,
      EmailID: "",
      appVersion: "1",
      imeiNumber: "not found",
      countryCode: "91",
      deviceOS: "Web",
      mobile: Mobile,
      countryName: "India",
      appVersionName: "1",
      deviceCountry: "India",
      deviceID: "6b068d3854a26559",
      deviceName: "Web",
      collectionNumber: "1", // for old use 0 and for new 1
    };
    fetch(baseURL + "/api/DynamicApp/checkMobileUserMongoDynamic", {
      method: "POST",
      headers: {
        // "Authorization-Token": AuthkeyTEXT,
        Authkey: AuthkeyTEXT,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(checkMobileUserPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          setNewUser("No");
          mobileSendOtpMongo();
        } else {
          setNewUser("Yes");
          mobileSendOtpNew();
          // alert("Invalid mobile number.");
        }
      });
    });
  }
  const fun_ChecksacchasathiData = () => {
    // var fullName = [];
    let UserID = "{'LoginMobile':" + Mobile + "}";
    const loginPra =
    {
      filters: UserID,
      tabErpID: "1429",
      collectionNumber: "1"
    }
    fetch("https://dialerpwebapis-dev-as.azurewebsites.net/api/DynamicApp/checkValidFilterUserMongoDynamicV1", {
      method: "POST",
      headers: {
        "Authkey": AuthkeyTEXT,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(loginPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response !== null) {
            if (resp.response?.UserGroup === "Saccha Sathi") {
              setIsSendOTPDisabled(true);
              checkMobileUserMongoDynamic();
            }
            else {
              alert("Mobile Number Already Exists in Canwinn Member")
              return;
            }
          } else {
            setIsSendOTPDisabled(true);
            checkMobileUserMongoDynamic();
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  };

  const CheckMobileNo = () => {
    debugger
    if (Mobile === "" || Mobile === null || Mobile === undefined) {
      alert("Enter Mobile No");
      return;
    } else if (Mobile.length < 10) {
      alert("Enter Valid Mobile No");
    } else {
      // setIsSendOTPDisabled(true);
      // checkMobileUserMongoDynamic();
      fun_ChecksacchasathiData();
    }
  };
  const [Name, setName] = useState();
  const [FatherName, setFatherName] = useState();
  const [Gender, setGender] = useState();
  const [DayDOB, setDayDOB] = useState();
  const [MonthName, setMonthName] = useState();
  const [YearDOB, setYearDOB] = useState();
  const [invitedbyMOB, setinvitedbyMOB] = useState();
  const [BGroup, setBGroup] = useState();

  function fun_CustomerRagistration() {
    debugger;
    if (Name === "" || Name === undefined) {
      alert("Enter Name");
      return;
    } 
    // else if (FatherName === "" || FatherName === undefined) {
    //   alert("Enter Father Name");
    //   return;
    // }
     else if (Gender === "" || Gender === undefined) {
      alert("Select Gender");
      return;
    } else if (BGroup === "" ||BGroup === null || BGroup === undefined) {
      alert("Select Blood Group");
      return;
    } else if (
      DayDOB === "" ||
      MonthName === "" ||
      YearDOB === "" ||
      YearDOB === undefined ||
      DayDOB === undefined ||
      MonthName === undefined
    ) {
      alert("Enter DOB");
      return;
    } else if (ProfileImg === "" || ProfileImg === undefined) {
      alert("Select Profile Img");
      return;
    }
    //  else if (invitedbyMOB === "" || invitedbyMOB === undefined) {
    //   alert("Enter invitedby MOB ");
    //   return;
    // }
    else {
      const formData = [
        {
          UserName: Name,
          UserGroup: "Saccha Sathi",
          // "UserGroupID": 1,
          LedgerGroup: "Freelancers",
          // "LedgerGroupID": 32,
          // Gender:Gender,
          UserRank: 1,
          Status: "Active",
          CountryCode: 91,
          LoginMobile: parseInt(Mobile),
          FullName: Name,
          //DOB: DayDOB + "-" + MonthName + "-" + YearDOB,
          ProfilePic: ProfileImg,
          ReferralCode: parseInt(invitedbyMOB),
        },
      ];
      fetch(
        baseURL + "/api/DynamicApp/UserSignupDynamic?LoginType=2&tabErpID=1429",
        {
          method: "POST",
          headers: {
            Authkey: AuthkeyTEXT,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(formData),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            setid(resp.response.id);
            setpagestep(2);
            AutocheckMobileUserMongoDynamic();
          } else {
            alert(resp.errors);
          }
        });
      });
    }
  }
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  }
  function verifyexistingMobileSendOtp() {
    if (OTP === "" || OTP === null || OTP === undefined) {
      alert("Enter OTP");
      return;
    } else {
      const VerifyPra = {
        baseTokenKey: AuthkeyTEXT,
        EmailID: "",
        appVersion: "1",
        deviceOS: "Web",
        mobile: Mobile,
        tabErpID: "1429",
        otp: OTP,
        appVersionName: "1",
        deviceCountry: "India",
        deviceID: "Web",
        deviceName: "xiaomi Mi A2",
        imeiNumber: "not found",
        countryCode: "91",
        countryName: "India",
        collectionNumber: "1", // for old use 0 and for new 1
      };
      fetch(baseURL + "/api/DynamicApp/mobileLoginVerifyOTPMongo", {
        method: "POST",
        headers: {
          // "Authorization-Token": AuthkeyTEXT,
          Authkey: AuthkeyTEXT,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(VerifyPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            localStorage.setItem(
              "Auth-Token",
              JSON.stringify(resp?.response?.loginKey)
            );
            localStorage.setItem("MobileNo", JSON.stringify(Mobile));
            localStorage.setItem(
              "user-Logininfo",
              JSON.stringify(resp?.response)
            );
            fun_ProfileData(resp?.response?.loginKey);
          } else {
            alert(resp.errors);
          }
        });
      });
    }
  }
  const uploadFile = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("ProfileImg", file);
      axios
        .post(
          "https://dialerpwebapis-dev-as.azurewebsites.net/api/UploadData/UploadCommonFilesToAzure?userID=40019&parentID=40019",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization-Token": authToken,
            },
          }
        )
        .then((response) => {
          debugger;
          if (response.data.hasErrors === true) {
            alert(response.data.errors);
          } else if (response.data.response !== null) {
            setProfileImg(response.data.response[0].imageUrl);
          }
        })
        .catch((error) => {
          console.error(
            "There was a problem with your fetch operation:",
            error
          );
        });
    }
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      uploadFile(file); // Call the uploadFile function with the selected file
    } else {
      console.error("No file selected.");
    }
  };

  const keymobileup = () => {
    setShowOTP(false);
    setOTP("");
    setIsSendOTPDisabled(false);
  };
  // useEffect(() => {
  //   localStorage.clear("Auth-Token");
  //   localStorage.removeItem("MobileNo");
  //   localStorage.removeItem("user-Logininfo");
  // }, [0]);

  const [Address1, setAddress1] = useState("");
  const [Address2, setAddress2] = useState("");
  const [Pincode, setPincode] = useState();
  // const [showaddress, setshowaddress] = useState(false);
  const [City, setCity] = useState("");
  const [CityID, setCityID] = useState("");
  const [State, setState] = useState("");
  const [StateCode, setStateCode] = useState("");
  const [StateID, setStateID] = useState("");
  const [Country, setCountry] = useState("");
  const [CountryID, setCountryID] = useState("");

  const [Landmark, setLandmark] = useState();
  const DoJ = moment(new Date()).format("DD-MMM-yyyy");
  var AuthTokenAuto = JSON.parse(localStorage.getItem("Auth-Token"));

  function getdropdataIOS(value) {
    if (AuthTokenAuto != null && AuthTokenAuto !== "") {
      let PincodeValue = "{Pincode:" + value + "}";
      const newdata = {
        useAtlusSearch: true,
        userID: 40119,
        filters: "{tabErpID:1746}",
        index: 1,
        isDownload: false,
        toEmailID: "designMonu@dialerp.com",
        tabErpID: 1746,
        dataMapper: {
          CollectionName: "FINANCECONNECT_Pincode",
          filter: PincodeValue,
          project:
            "{'Pincode':'$Pincode','City':'$City','CityID':'$CityID','State':'$State','StateID':'$StateID','StateCode':'$StateCode','Country':'$Country','CountryID':'$CountryID'}",
          suggestedText: "",
        },
      };
      fetch(
        baseURL +
        "/api/dynamic/AutoFillMapperWise?appname=web&&tmpUId=40119&tmpPId=40119",
        {
          method: "POST",
          headers: {
            // "Authorization-Token": AuthkeyTEXT,
            "Authorization-Token": AuthTokenAuto,
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify(newdata),
        }
      ).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            if (resp?.response?.records !== null) {
              //setdrop(response.data);
              // setshowaddress(true);
              setCity(resp?.response?.records[0]["City"]);
              setState(resp?.response?.records[0]["State"]);
              setCountry(resp?.response?.records[0]["Country"]);
              setStateCode(resp?.response?.records[0]["StateCode"]);
              setCityID(resp?.response?.records[0]["CityID"]);
              setStateID(resp?.response?.records[0]["StateID"]);
              setCountryID(resp?.response?.records[0]["CountryID"]);
            } else {
              // setshowaddress(false);
              setCity("");
              setState("");
              setCountry("");
              setStateCode("");
              setCityID("");
              setStateID("");
              setCountryID("");
              alert("Enter valid pincode");
            }
          }
        });
      });
    } else {
      AutocheckMobileUserMongoDynamic();
    }
  }
  function handleKeyPressPincode(value) {
    setPincode(value);
    if (value?.length !== 6) {
      setCity("");
      setState("");
      setCountry("");
      setStateCode("");
      setCityID("");
      setStateID("");
      setCountryID("");
    }
    if (value?.length === 6) {
      getdropdataIOS(value);
      // getdropdata(value);
    }
  }

  function fun_CustomerRagistration_() {
    debugger;
    if (Pincode === "" || Pincode === undefined) {
      alert("Enter PinCode");
      return;
    } else if (Address1 === "" || Address1 === undefined) {
      alert("Enter Address Line 1");
      return;
    } else if (City === "" || City === undefined) {
      alert("Enter PinCode");
      return;
    } else {
      if (AuthTokenAuto != null && AuthTokenAuto !== "") {
        const formData = [
          {
            LedgerName: Mobile,
            LedgerGroup: "Saccha Sathi",
            LedgerGroupID: 501,
            UnderLedgerGroupID: "32",
            Status: "Active",
            StatusID: 2,
            ContactName: Name,
            ContactMobile: parseInt(Mobile),
            Address: Address1,
            // AddressLine2: Address2,
            // setLandmark: Landmark,
            Pincode: Pincode,
            City: City,
            CityID: CityID,
            State: State,
            StateID: StateID,
            StateCode: StateCode,
            Country: Country,
            CountryID: CountryID,
            Gender: Gender,
            //FatherName: FatherName,
            DOJ: DoJ,
            Designation: "Saccha Sathi",
            Role :"Saccha Sathi",
            Profile  :"Saccha Sathi",
            DesignationID: 1,
            Department: "Support",
            DepartmentID: 2,
            Branch: "Canwinn Foundation",
            BranchID: 1001,
            VirtualOffice: "Gurgaon HO",
            VirtualOfficeID: 1,
            Shift: "Day Shift",
            ShiftID: 1,
            LoginMobile: parseInt(Mobile),
            UserName: Name,
            UserID: id,
            UserGroup: "Saccha Sathi",
            UserGroupID: 1,
           // RefVerified: BGroup,
            LogoPic: ProfileImg,
            DOB: DayDOB + "-" + MonthName + "-" + YearDOB,
            AlaisName	:FatherName,
            AddressType	:BGroup,
          },
        ];
        fetch(
          baseURL +
          "/api/Dynamic/AddUpdateRecords?tabErpID=1746&tabName=Freelancers&buttonFilter=&appname=web&tmpUId=40119&tmpPId=40119",
          {
            method: "POST",
            headers: {
              "Authorization-Token": AuthTokenAuto,
              Accept: "application/json, text/plain",
              "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(formData),
          }
        ).then((result) => {
          result.json().then((resp) => {
            if (resp.hasErrors === false) {
              setid(resp.response.id);
              setpagestep(3);
            } else {
              alert(resp.errors);
            }
          });
        });
      } else {
        AutocheckMobileUserMongoDynamic();
      }
    }
  }
  function autoverifyexistingMobileSendOtp(autootp) {
    if (autootp === "" || autootp === null || autootp === undefined) {
      alert("Enter OTP");
      return;
    } else {
      const VerifyPra = {
        baseTokenKey: AuthkeyTEXT,
        EmailID: "",
        appVersion: "1",
        deviceOS: "Web",
        mobile: Mobile,
        tabErpID: "1429",
        otp: autootp,
        appVersionName: "1",
        deviceCountry: "India",
        deviceID: "Web",
        deviceName: "xiaomi Mi A2",
        imeiNumber: "not found",
        countryCode: "91",
        countryName: "India",
        collectionNumber: "1", // for old use 0 and for new 1
      };
      fetch(baseURL + "/api/DynamicApp/mobileLoginVerifyOTPMongo", {
        method: "POST",
        headers: {
          // "Authorization-Token": AuthkeyTEXT,
          Authkey: AuthkeyTEXT,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(VerifyPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.hasErrors === false) {
            localStorage.clear();
            localStorage.setItem(
              "Auth-Token",
              JSON.stringify(resp?.response?.loginKey)
            );
            localStorage.setItem("MobileNo", JSON.stringify(Mobile));
            localStorage.setItem(
              "user-Logininfo",
              JSON.stringify(resp?.response)
            );
          } else {
            alert(resp.errors);
          }
        });
      });
    }
  }
  const autoDecryptN1 = (encryptedText) => {
    const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedText);
    const decryptedHex = CryptoJS.AES.decrypt(
      { ciphertext: encryptedBytes },
      CryptoJS.enc.Utf8.parse(key),
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(key),
        keySize: 128,
        blockSize: 128,
      }
    );
    const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedHex);
    if (
      decryptedText !== undefined &&
      decryptedText !== "undefined" &&
      decryptedText !== "" &&
      decryptedText !== null
    ) {
      // setOTP(decryptedText);
      autoverifyexistingMobileSendOtp(decryptedText);
    } else {
      autoDecryptN1(encryptedText);
    }
  };
  const automobileSendOtpMongo = () => {
    if (Mobile === "") {
      alert("Enter Mobile Number");
      return;
    } else {
      const loginPra = {
        baseTokenKey: AuthkeyTEXT,
        resend: "0",
        mobile: Mobile,
      };
      fetch(baseURL + "/api/DynamicApp/mobileSendOtpMongo", {
        method: "POST",
        headers: {
          // "Authorization-Token": AuthkeyTEXT,
          Authkey: AuthkeyTEXT,
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginPra),
      }).then((result) => {
        result.json().then((resp) => {
          if (resp?.hasErrors === false) {
            autoDecryptN1(resp.response);
          } else {
            alert("Invalid request!!.");
            return;
          }
        });
      });
    }
  };
  function AutocheckMobileUserMongoDynamic() {
    const checkMobileUserPra = {
      baseTokenKey: AuthkeyTEXT,
      EmailID: "",
      appVersion: "1",
      imeiNumber: "not found",
      countryCode: "91",
      deviceOS: "Web",
      mobile: Mobile,
      countryName: "India",
      appVersionName: "1",
      deviceCountry: "India",
      deviceID: "6b068d3854a26559",
      deviceName: "Web",
      collectionNumber: "1", // for old use 0 and for new 1
    };
    fetch(baseURL + "/api/DynamicApp/checkMobileUserMongoDynamic", {
      method: "POST",
      headers: {
        // "Authorization-Token": AuthkeyTEXT,
        Authkey: AuthkeyTEXT,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(checkMobileUserPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          automobileSendOtpMongo();
        }
      });
    });
  }
  const gotoCard = () => {
    document.getElementById("Login1").click();
  };

  const [addLegerData, setaddLegerData] = useState("No");
  const fun_ProfileData = (key) => {
    // var fullName = [];
    let UserID = "{'LoginMobile':" + Mobile + ",'UnderLedgerGroupID':32}";
    const loginPra = {
      pgSize: 100,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1746,
      filters: UserID,
    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": key,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(loginPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              document.getElementById("Login1").click();
              // setuserdata(resp?.response?.records?.[0]);
              // setUserName(resp.response?.records[0]["User Name"]);
              // localStorage.setItem("LoginorNot",JSON.stringify(resp.response?.records[0]["User Name"]));
              // setUserID(resp.response?.records[0]["LedgerID"]);
              // setprofileimg(resp.response?.records[0]["Logo Pic"]);
              // setDOB(
              //   moment(resp.response?.records[0]["DOB"]).format("DD-MMM-yyyy")
              // );
              // setGender(resp.response?.records[0]["Gender"]);
              // setAddress(resp.response?.records[0]["Address Line 1"] + "," +
              //   // resp.response?.records[0]["Address Line 2"] +", "+
              //   // resp.response?.records[0]["Landmark"] +", "+
              //   resp.response?.records[0]["City"] + ", " +
              //   resp.response?.records[0]["State"] + " (" +
              //   resp.response?.records[0]["Pincode"] + ")"
              // );
              // setFatherName(resp.response?.records[0]["Father Name"]);
              // setBGroup(resp.response?.records[0]["Blood Group"]);
            } else {
              fun_UserDetailsData();
            }
          } else {
            fun_UserDetailsData();
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  };
  const fun_UserDetailsData = () => {
    // var fullName = [];
    let UserID = "{'LoginMobile':" + Mobile + "}";
    const loginPra = {
      pgSize: 100,
      index: 1,
      toEmailID: "dpgoel@canwinn.org",
      tabErpID: 1429,
      filters: UserID,
    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": AuthTokenAuto,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(loginPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            console.log(resp.response?.records, "User Data");

            if (resp.response?.records.length > 0) {
              setid(resp.response?.records[0]["UserID"]);
              setName(resp.response?.records[0]["Full Name"]);
              setProfileImg(resp.response?.records[0]["ProfilePic"]);
              setaddLegerData("Yes");
              setpagestep(1);
            }
          } else {
            setaddLegerData("No");
            setpagestep(0);
            localStorage.clear();
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  };
  function fun_CustomerRagistrationValidation() {
    debugger;
    if (Name === "" || Name === undefined) {
      alert("Enter Name");
      return;
    } 
    // else if (FatherName === "" || FatherName === undefined) {
    //   alert("Enter Father Name");
    //   return;
    // }
    else if (Gender === "" || Gender === undefined) {
      alert("Select Gender");
      return;
    } else if (BGroup === "" || BGroup === undefined) {
      alert("Select Blood Group");
      return;
    } else if (
      DayDOB === "" ||
      MonthName === "" ||
      YearDOB === "" ||
      YearDOB === undefined ||
      DayDOB === undefined ||
      MonthName === undefined
    ) {
      alert("Enter DOB");
      return;
    } else if (ProfileImg === "" || ProfileImg === undefined) {
      alert("Select Profile Img");
      return;
    }
    //  else if (invitedbyMOB === "" || invitedbyMOB === undefined) {
    //   alert("Enter invitedby MOB ");
    //   return;
    // }
    else {
      setpagestep(2);
    }
  }

  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setImage(file);
  };
  const handleScaleChange = (e) => {
    const scale = parseFloat(e.target.value);
    editor &&
      editor.getImageScaledToCanvas().toBlob(
        (blob) => {
          setCroppedImage(blob);
        },
        "image/jpeg",
        scale
      );
  };

  const handleSave = () => {
    debugger;
    if (croppedImage) {
      const canvas = editor.getImageScaledToCanvas();
      canvas.toBlob(
        (blob) => {
          const formData = new FormData();
          formData.append("ProfileImg", blob, "cropped_image.jpg");
          axios
            .post(
              "https://dialerpwebapis-dev-as.azurewebsites.net/api/UploadData/UploadCommonFilesToAzure?userID=40019&parentID=40019",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "Authorization-Token": authToken,
                },
              }
            )
            .then((response) => {
              debugger;
              if (response.data.hasErrors === true) {
                alert(response.data.errors);
              } else if (response.data.response !== null) {
                setProfileImg(response.data.response[0].imageUrl);
                handleClose();
              }
            })
            .catch((error) => {
              console.error(
                "There was a problem with your fetch operation:",
                error
              );
            });
        },
        "image/jpeg",
        1
      ); // Quality 1 (max quality)
    } else {
      // Handle the case when there is no cropped image
      alert("No cropped image to save");
    }
  };
  useEffect(() => {
    if (editor && image) {
      editor.getImageScaledToCanvas().toBlob(
        (blob) => {
          setCroppedImage(blob);
        },
        "image/jpeg",
        1
      );
    }
  }, [image, editor]);
  return (
    <div>
      <Link to="/printCard" id="Login1"></Link>
      <Header />
      <div className="banner">
        <Container>
          <Row>
            {/* <Link to="/Crop" id="Login1">Crop</Link> */}

            <Col md={6}></Col>
            <Col md={6}>
              <div className="formdesign">
                <Col md={12}>
                  {pagestep === 0 ? (
                    <h1>Sign-In / Join Canwinn Saccha Sathi</h1>
                  ) : (
                    <h1>Join Canwinn Saccha Sathi</h1>
                  )}
                  {/* <h1>Sign-In / Join Canwinn Saccha Sathi</h1> */}
                  <Box sx={{ width: "100%" }}>
                    {/* <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                          labelProps.optional = (
                            <Typography variant="caption"></Typography>
                          );
                        }
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper> */}
                    {/* <React.Fragment> */}
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      {pagestep === 0 && (
                        <div className="MobileDiv1">
                          <h2>Personal Detail</h2>
                          <Row>
                            <Col md={9} xs={8}>
                              <div className="input-spce-bottom">
                                <TextField
                                  floatingLabelText="number"
                                  autoFocus={true}
                                  defaultValue="+91 | "
                                  className={`width-100 lgoin-input-6`}
                                  label="Mobile Number"
                                  maxLength={10}
                                  required
                                  variant="outlined"
                                  onInput={(e) => {
                                    e.target.value = e.target.value
                                      .toString()
                                      .slice(0, 10);
                                  }}
                                  value={Mobile}
                                  onChange={(e) => {
                                    keymobileup();
                                    setMobile(e.target.value);
                                  }}
                                  id="validation-outlined-input"
                                  type="number"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {" "}
                                        +91{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                />

                                {/* <div className="invalid-feedback2">
                                  <p>Please enter mobile number</p>
                                </div> */}
                              </div>
                            </Col>
                            <Col md={3} xs={4} className="RightSide">
                              <button
                                type="submit"
                                className="btn btn-primary btn-lg btn-theme valid-mobile valid-mobile22"
                                onClick={() => {
                                  CheckMobileNo();
                                }}
                                disabled={isSendOTPDisabled}
                                variant="contained"
                              >
                                {" "}
                                Send OTP
                              </button>
                            </Col>
                          </Row>
                          <br />
                          {showOTP && (
                            <div className="form-group clearfix">
                              <Row>
                                <Col md={12} xs={12}>
                                  <div className="input-OTP">
                                    <TextField
                                      floatingLabelText="number"
                                      autoFocus={true}
                                      className={`input-width OTPCss`}
                                      label="Enter OTP"
                                      maxLength={4}
                                      required
                                      variant="outlined"
                                      // inputProps={{ maxLength: 10 }}
                                      //max={10}
                                      onInput={(e) => {
                                        e.target.value = e.target.value
                                          .toString()
                                          .slice(0, 4);
                                      }}
                                      value={OTP}
                                      onChange={(e) => {
                                        setOTP(e.target.value);
                                      }}
                                      id="validation-outlined-input"
                                      type="number"
                                    />
                                  </div>
                                </Col>
                                <div className="resenddiv">
                                  {isResendDisabled ? (
                                    <p>
                                      0
                                      {`${Math.floor(timer / 60)}:${timer % 60 < 10 ? "0" : ""
                                        }${timer % 60}`}
                                    </p>
                                  ) : (
                                    <Button
                                      onClick={() =>
                                        NewUser === "No"
                                          ? mobileSendOtpMongo()
                                          : mobileSendOtpNew()
                                      }
                                    >
                                      Resend OTP
                                    </Button>
                                  )}
                                </div>
                              </Row>
                              <div className="NextButton clearfix">
                                {pagestep === 0 && NewUser === "Yes" && (
                                  <Button
                                    onClick={() => {
                                      verifyMobileSendOtp();
                                    }}
                                    variant="contained"
                                    className="NextButton1"
                                  >
                                    Next
                                  </Button>
                                )}
                                {pagestep === 0 && NewUser === "No" && (
                                  <Button
                                    onClick={() => {
                                      verifyexistingMobileSendOtp();
                                    }}
                                    variant="contained"
                                    className="NextButton1"
                                  >
                                    Next
                                  </Button>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {pagestep === 1 && (
                        <div className="MobileDiv">
                          <h2>Contact Detail</h2>
                          <Form className="formClass">
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>
                                    Enter Your Name{" "}
                                    <span className="clsRequest">*</span>
                                  </Form.Label>
                                  <Form.Control
                                    type="email"
                                    placeholder="Enter Your Name"
                                    className="input-2"
                                    value={Name}
                                    onChange={(e) => {
                                      setName(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>
                                    Enter Father Name
                                  
                                  </Form.Label>
                                  <Form.Control
                                    type="email"
                                    value={FatherName}
                                    onChange={(e) => {
                                      setFatherName(e.target.value);
                                    }}
                                    className="input-2"
                                    placeholder="Father Name"
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>
                                    Select Gender
                                    <span className="clsRequest">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Default select example1"
                                    className="input-2"
                                    value={Gender}
                                    onChange={(e) => {
                                      setGender(e.target.value);
                                    }}
                                  >
                                    <option>Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>
                                    Blood Group
                                    <span className="clsRequest">*</span>
                                  </Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="input-2"
                                    value={BGroup}
                                    onChange={(e) => {
                                      setBGroup(e.target.value);
                                    }}
                                  >
                                    <option>Select Blood Group</option>
                                    <option value="A+">A+</option>
                                    <option value="A-">A-</option>
                                    <option value="B+">B+</option>
                                    <option value="B-">B-</option>
                                    <option value="O+">O+</option>
                                    <option value="O-">O-</option>
                                    <option value="AB+">AB+</option>
                                    <option value="AB-">AB-</option>
                                    <option value="NA">NA</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Form.Label>
                                Date of Birth
                                <span className="clsRequest">*</span>
                              </Form.Label>
                              <Col md={4} xs={4}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Control
                                    type="number"
                                    className="input-2"
                                    placeholder="Enter Date"
                                    value={DayDOB}
                                    floatingLabelText="number"
                                    maxLength={2}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .toString()
                                        .slice(0, 2);
                                    }}
                                    // inputProps={{ maxLength: 2 }}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    onChange={(e) => {
                                      setDayDOB(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              <Col md={4} xs={4}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Select
                                    aria-label="Default select example"
                                    className="input-2"
                                    value={MonthName}
                                    onChange={(e) => {
                                      setMonthName(e.target.value);
                                    }}
                                  >
                                    <option>Month</option>
                                    <option value="Jan">January</option>
                                    <option value="Feb">February</option>
                                    <option value="Mar">March</option>
                                    <option value="Apr">April</option>
                                    <option value="May">May</option>
                                    <option value="Jun">June</option>
                                    <option value="Jul">July</option>
                                    <option value="Aug">August</option>
                                    <option value="Sep">September</option>
                                    <option value="Oct">October</option>
                                    <option value="Nov">November</option>
                                    <option value="Dec">December</option>
                                  </Form.Select>
                                </Form.Group>
                              </Col>
                              <Col md={4} xs={4}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Control
                                    type="number"
                                    className="input-2"
                                    placeholder="Enter Year"
                                    value={YearDOB}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    floatingLabelText="number"
                                    maxLength={4}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .toString()
                                        .slice(0, 4);
                                    }}
                                    onChange={(e) => {
                                      setYearDOB(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>
                                    Refer By [Enter Mobile Number]
                                  </Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter Mobile Number"
                                    className="input-2"
                                    floatingLabelText="number"
                                    maxLength={10}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .toString()
                                        .slice(0, 10);
                                    }}
                                    value={invitedbyMOB}
                                    onChange={(e) => {
                                      setinvitedbyMOB(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Form.Group controlId="formFileLg" className="mb-3">
                              <Form.Label className="upload1">
                                Upload Profile Pic
                                <span className="clsRequest">*</span>
                              </Form.Label>
                              {/* <Form.Control
                                type="file"
                                size="lg"
                                accept=".jpg, .jpeg, .png"
                                capture="camera"
                                onChange={handleClickOpen}
                              /> */}

                              <section className="dropzone-container">
                                <div className="dropzone">
                                  {ProfileImg ? (
                                    <div className="ImageChange1">
                                      <img
                                        src={ProfileImg}
                                        alt="icon"
                                        style={{
                                          width: "80px",
                                          height: "80px",
                                        }}
                                      />
                                      <p
                                        onClick={() => {
                                          handleClickOpen();
                                        }}
                                      >
                                        Changes Photo
                                      </p>
                                    </div>
                                  ) : (
                                    <h4
                                      onClick={() => {
                                        handleClickOpen();
                                      }}
                                      className="clickhere"
                                    >
                                      Click to Here
                                    </h4>
                                  )}
                                </div>
                                {/* <div className="file-info">
        <p>Supported formats: .jpg, .jpeg, .png,</p>
        <p>Max file size: 10MB</p>
      </div> */}
                              </section>
                            </Form.Group>
                          </Form>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Button
                              color="inherit"
                              disabled={pagestep === 0}
                              onClick={handleBack}
                              sx={{ mr: 1 }}
                            >
                              Back
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />
                            {pagestep === 1 && addLegerData === "No" && (
                              <Button
                                onClick={() => {
                                  fun_CustomerRagistration();
                                }}
                                variant="contained"
                                className="buuton2"
                              >
                                Next
                              </Button>
                            )}
                            {pagestep === 1 && addLegerData === "Yes" && (
                              <Button
                                onClick={() => {
                                  fun_CustomerRagistrationValidation();
                                }}
                                variant="contained"
                                className="buuton2"
                              >
                                Next
                              </Button>
                            )}
                          </Box>
                        </div>
                      )}
                      {pagestep === 2 && (
                        <div className="MobileDiv">
                          <h2>Address Detail</h2>
                          <Form className="formClass">
                            <Row>
                              <Col md={12}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>Address</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    rows={2} // specify the number of rows for the textarea
                                    placeholder="Enter Address"
                                    className="input-2"
                                    value={Address1}
                                    onChange={(e) => {
                                      setAddress1(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              {/* <Col md={12}>
                                    <Form.Group className="mb-3" controlId="">
                                      <Form.Label>Address Line 2</Form.Label>
                                      <Form.Control type="text" placeholder="Enter Address Line 2" className="input-2" value={Address2}
                                        onChange={(e) => { setAddress2(e.target.value); }}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md={12}>
                                    <Form.Group className="mb-3" controlId="">
                                      <Form.Label>Landmark</Form.Label>
                                      <Form.Control type="text" placeholder="Enter Landmark" className="input-2" value={Landmark}
                                        onChange={(e) => { setLandmark(e.target.value); }}
                                      />
                                    </Form.Group>
                                  </Col> */}
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>Enter Pincode*</Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Enter PinCode"
                                    className="input-2"
                                    floatingLabelText="number"
                                    maxLength={4}
                                    onInput={(e) => {
                                      e.target.value = e.target.value
                                        .toString()
                                        .slice(0, 6);
                                    }}
                                    handleKeyPressPincode
                                    value={Pincode}
                                    onChange={(e) => {
                                      handleKeyPressPincode(e.target.value);
                                    }}
                                  />
                                </Form.Group>
                              </Col>
                              {/* <Col md={2}>
                                {Pincode?.length === 6 ?
                                  <Button onClick={() => { getdropdata(); }} style={{ marginTop: "30px" }} variant="contained" className="buuton2">
                                    Go
                                  </Button>
                                  :
                                  <Button disabled onClick={() => { getdropdata(); }} style={{ marginTop: "30px" }} variant="contained" className="buuton2">
                                    Go
                                  </Button>
                                }
                              </Col> */}

                              <Col md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>City</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="City"
                                    className="input-2"
                                    value={City}
                                    disabled
                                  // onChange={(e) => { setCity(e.target.value); }}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>State</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Statee"
                                    className="input-2"
                                    value={State}
                                    disabled
                                  // onChange={(e) => { setState(e.target.value); }}
                                  />
                                </Form.Group>
                              </Col>

                              {/* <Col md={6}>
                                <Form.Group className="mb-3" controlId="">
                                  <Form.Label>Country</Form.Label>
                                  <Form.Control type="text" placeholder="Country" className="input-2" value={Country} disabled
                                  // onChange={(e) => { setCountry(e.target.value); }}
                                  />
                                </Form.Group>
                              </Col> */}
                            </Row>
                          </Form>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            {/* <Button color="inherit" disabled={pagestep === 0} onClick={handleBack} sx={{ mr: 1 }} > Back</Button> */}
                            <Box sx={{ flex: "1 1 auto" }} />
                            {pagestep === 2 ? (
                              <Button
                                onClick={() => {
                                  fun_CustomerRagistration_();
                                }}
                                variant="contained"
                                className="buuton2"
                              >
                                Create My Account
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                className="buuton2"
                                disabled
                              >
                                {" "}
                                Create My Account1{" "}
                              </Button>
                            )}
                          </Box>
                        </div>
                      )}
                      {pagestep === 3 && (
                        <div className="MobileDiv3">
                          <h2>Registeration Successfull</h2>
                          <br />
                          <p>
                            <b>Dear {Name},</b>
                          </p>
                          <p>
                            You have successfully registered with Canwinn
                            Foundation. Your Sachha Sathi id is: {id}.
                          </p>
                          <br />
                          <p>
                            Thankyou
                            <br />
                            Canwinn Team
                          </p>
                          <br />
                          <Button
                            onClick={() => {
                              gotoCard();
                            }}
                            variant="contained"
                          >
                            Download Card
                          </Button>
                        </div>
                      )}
                    </Typography>
                    {/* </React.Fragment> */}
                  </Box>
                </Col>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="modelbox1"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Upload and Crop Profile Picture
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <section className="dropzone-container">
                <div {...getRootProps()} className="dropzone22">
                  <input {...getInputProps()} />
                  <p>Click to select profile</p>
                </div>
                {/* <div className="file-info">
        <p>Supported formats: .jpg, .jpeg, .png,</p>
        <p>Max file size: 10MB</p>
      </div> */}
              </section>
            )}
          </Dropzone>
          {image && (
            <div className="ViewImage">
              <h4>Preview and Crop</h4>
              <AvatarEditor
                ref={(ref) => setEditor(ref)}
                image={image}
                width={300}
                height={300}
                border={20}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={1}
                rotate={0}
                className="avatar-editor"
              />
              <div className="scale2">
                <label>Scale:</label>
                <input
                  type="range"
                  min="1"
                  max="2"
                  step="0.01"
                  defaultValue="1"
                  id="id_handleScaleChange"
                  onChange={handleScaleChange}
                />
              </div>
              {/* <button onClick={handleSave}>Save</button> */}
            </div>
          )}
          {image && (
            <Button
              autoFocus
              onClick={() => {
                handleSave();
              }}
              variant="contained"
              className="savebutton2"
            >
              Save changes
            </Button>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default Home;
