import logo from "./logo.svg";
import "./App.css";

import React, {
  Component,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  useParams,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";
import Home from "./Component/Home";
import IDCart from "./Component/IDCart";
import ProfilePicUploader from "./Component/ProfilePicUploader";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/printCard" element={<IDCart />} />
        <Route path="/*" element={<Navigate to="/" />} />
        <Route path="/Crop" element={<ProfilePicUploader />} />
      </Routes>
    </div>
  );
}

export default App;
