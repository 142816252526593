import React, { useState ,useEffect} from 'react';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import axios from "axios";

const ProfilePicUploader = () => {
  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`.replace(/'/g, "");

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setImage(file);
  };

  const handleScaleChange = (e) => {
    const scale = parseFloat(e.target.value);
    editor && editor.getImageScaledToCanvas().toBlob((blob) => {
      setCroppedImage(blob);
    }, 'image/jpeg', scale);
  };

  const handleSave = () => {
    debugger
    if (croppedImage) {
      const canvas = editor.getImageScaledToCanvas();
      canvas.toBlob((blob) => {
        const formData = new FormData();
        formData.append("ProfileImg", blob, "cropped_image.jpg");

        axios
          .post(
            "https://dialerpwebapis-dev-as.azurewebsites.net/api/UploadData/UploadCommonFilesToAzure?userID=40019&parentID=40019",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Authorization-Token": authToken,
              },
            }
          )
          .then((response) => {
            debugger
            if (response.data.hasErrors === true) {
              alert(response.data.errors);
            } else if (response.data.response !== null) {
              alert(response.data.response[0].imageUrl);
            }
          })
          .catch((error) => {
            console.error(
              "There was a problem with your fetch operation:",
              error
            );
          });
      }, 'image/jpeg', 1); // Quality 1 (max quality)
    }
  };
  
  return (
    <div>
      <h2>Upload and Crop Profile Picture</h2>
      <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <section className="dropzone-container">
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <p>Click to select Profile</p>
            </div>
            {/* <div className="file-info">
        <p>Supported formats: .jpg, .jpeg, .png,</p>
        <p>Max file size: 10MB</p>
      </div> */}
          </section>
        )}
      </Dropzone>
      {image && (
        <div>
          <h3>Preview and Crop</h3>
          <AvatarEditor
            ref={(ref) => setEditor(ref)}
            image={image}
            width={300}
            height={300}
            border={50}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1}
            rotate={0}
            onImageChange={() => {
              editor &&
                editor.getImageScaledToCanvas().toBlob((blob) => {
                  setCroppedImage(blob);
                }, 'image/jpeg', 1);
            }}
          />
          {/* <div>
            <label>Scale:</label>
            <input
              type="range"
              min="1"
              max="2"
              step="0.01"
              defaultValue="1"
              onChange={handleScaleChange}
            />
          </div> */}
          <button onClick={handleSave}>Save</button>
        </div>
      )}
    </div>
  );
};

export default ProfilePicUploader;
