import React, { useState, useEffect, useRef } from "react";
import HeaderCard from "./HeaderCard";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment";
import Button from "@mui/material/Button";
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
function IDCart() {
  var userLogininfo = JSON.parse(localStorage.getItem("user-Logininfo"));
  var MobileNo = JSON.parse(localStorage.getItem("MobileNo"));
  var pdflink = JSON.parse(localStorage.getItem("PDF-Link"));

  const [pdfUrl, setpdfUrl] = useState(pdflink);
  const [showbtn, setshowbtn] = useState(false);

  var Token = JSON.parse(localStorage.getItem("Auth-Token"));
  if (userLogininfo === null) {
    window.location.href = "/";
  }
  const imageRef = useRef(null);
  useEffect(() => {
    fun_ProfileData();
  }, []);

  const baseURL = process.env.REACT_APP_BASE_URL;
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`.replace(/'/g, "");
  const [userdata, setuserdata] = useState([]);
  const [DOB, setDOB] = useState();
  const [UserID, setUserID] = useState();
  const [profileimg, setprofileimg] = useState();
  const [UserName, setUserName] = useState();
  const [Gender, setGender] = useState();
  const [FatherName, setFatherName] = useState();
  const [BGroup, setBGroup] = useState();
  const [Address, setAddress] = useState();

  const fun_ProfileData = () => {
    if (pdflink !== null && pdflink !== "") {
      setshowbtn(true);
    }
    // var fullName = [];
    let UserID = "{'LoginMobile':" + MobileNo + ",'UnderLedgerGroupID':32}";
    const loginPra = {
      pgSize: 100,
      index: 1,
      toEmailID: "designMonu@dialerp.com",
      tabErpID: 1746,
      filters: UserID,
      viewFormat: "columnname",

    };
    fetch(baseURL + "/api/dynamic/GetRecords", {
      method: "POST",
      headers: {
        "Authorization-Token": Token,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(loginPra),
    }).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          if (resp.response?.records !== null) {
            if (resp.response?.records.length > 0) {
              debugger;
              setuserdata(resp?.response?.records?.[0]);
              setUserName(resp.response?.records[0]["ContactName"]);
              localStorage.setItem(
                "LoginorNot",
                JSON.stringify(resp.response?.records[0]["ContactName"])
              );
              setUserID(resp.response?.records[0]["LedgerID"]);
              setprofileimg(resp?.response?.records[0]["LogoPic"]);
              const dob = moment.utc(resp.response?.records[0]["DOB"]).format("DD-MMM-yyyy");
              setDOB(
                dob
              );
              setGender(resp.response?.records[0]["Gender"]);
              setAddress(
                resp.response?.records[0]["Address"] +
                ", " +
                // resp.response?.records[0]["Address Line 2"] +", "+
                // resp.response?.records[0]["Landmark"] +", "+
                resp.response?.records[0]["City"] +
                ", " +
                resp.response?.records[0]["State"] +
                " (" +
                resp.response?.records[0]["Pincode"] +
                ")"
              );
              // debugger;
              // console.log(resp.response?.records[0], "Hello1");

              setFatherName(resp.response?.records[0]["AlaisName"]);
              setBGroup(resp.response?.records[0]["AddressType"]);
            }
          }
        } else {
          alert(resp.errors);
        }
      });
    });
  };

  const downloadImage = () => {
    const element = document.getElementById("cart-detail");
    if (!element) {
      console.error('Element with ID "cart-detail" not found.');
      return;
    }
    toPng(element)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "User-Card.png";
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error generating image:", error);
      });
  };

  const fun_DownloadCardPDF = () => {
    setshowbtn(true);
    const element = document.getElementById("cart-detailpdf").innerHTML;
    if (!element) {
      console.error('Element with ID "cart-detail" not found.');
      return;
    }

    const loginPra = {
      userID: 40035,
      fileName: "Card-PDF",
      htmlData: element,
      header1: "",
      htmlData2: "",
      isEwayBill: 0,
      setOf: 1,
      toEmailID: "hitesh@colstonconcepts.com",
    };
    fetch(
      "https://colston.azurewebsites.net/api/common/downloadHTMlToPDFWithCustomHeader?appname=web&tmpUId=40119&tmpPId=40119",
      {
        method: "POST",
        headers: {
          "Authorization-Token":
            "n96yMN8pj0R8fAss9aheo+4FkxktA6tJ9RLiRtHVHKZI1fmdHoOJ8Kd0z08fecsYboCffvzYjP/W814yOmr9WL+Og4ohnr8nLLqwKm6ysHcGJvGzJhKExF8+pBxVbj9qDOSLQt+eeJ5PzSkXK3RWfpBBUv2HxR7L4TNsjw/nlNXp6ApBRH0PcZL8UfyCeOHR0rT9Y3r5KrQW1lBxe2QhHyjem/taK4j8HLuQJYGzS7X0pZTj4DWFxoshTfRENMvX",
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(loginPra),
      }
    ).then((result) => {
      result.json().then((resp) => {
        if (resp.hasErrors === false) {
          const data2 = `data:application/pdf;base64,${resp.response.arrayVoucher}`;
          localStorage.setItem("PDF-Link", JSON.stringify(data2));
          const link = document.createElement("a");
          link.download = "User-Card.pdf";
          link.href = data2;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // Create a temporary link element
          // const link = document.createElement('a');
          // link.href = data1;
          // link.download = `Card.pdf`; // Set the download filename

          // // Dispatch a click event on the link element
          // document.body.appendChild(link); // Append the link to the DOM
          // link.click(); // Simulate a click event
          // document.body.removeChild(link); // Remove the link from the DOM after download
          setpdfUrl(data2);
          setshowbtn(true);
        } else {
          alert(resp.errors);
          setshowbtn(false);
        }
      });
    });
  };
  return (
    <div>
      <HeaderCard />
      <a href={pdfUrl} target="_blank" id="pdfclick" hidden></a>
      <div className="PrintClass">
        <Container>
          <div className="MainCss2" id="cart-detail">

            <div style={{ padding: 15 }}>
              <div
                style={{
                  textAlign: "center !important",
                  borderTop: "1px solid #000 !important",
                  marginRight: "6px",
                  borderBottom: "1px solid #000 !important",
                  borderRight: "1px solid #000 !important",
                  borderLeft: "1px solid #000 !important",
                }}
              >
                <div className="LogoDIvText">
                  <img
                    src="https://dialerpstorage.blob.core.windows.net/40019/Actual_Common_IMG-40019-20240220012337.jpg"
                    className="Imagecss12"
                    alt="logo"
                    style={{
                      display: "inline-block",
                      verticalAlign: "baseline",
                      marginTop: "0px",
                      marginRight: "10px",
                      width: "65px",
                    }}
                  />
                  <h2
                    className="HeadingTitle"
                    style={{
                      width: "80%",
                      textAlign: "center",
                      display: "inline-block", fontWeight: '700'
                    }}
                  >
                    <span style={{ color: "#784aa3", display: "inline-block", fontWeight: '700' }}>
                      Can
                    </span>
                    <span
                      style={{
                        color: "#1db8ab",
                        marginRight: "5px",
                        marginBottom: "0px",
                        display: "inline-block", fontWeight: '700'
                      }}
                    >
                      {" "}
                      winn
                    </span>
                    Foundation
                    <p style={{ color: "#000", lineHeight: "12px", fontWeight: '700' }}>
                      <span
                        style={{
                          borderBottom: "2px solid #784aa3",
                          color: "#784aa3",
                          fontSize: "14px", fontSize: 22, fontWeight: 400
                        }}
                      >
                        {" "}
                        <b>Saccha Sathi Card</b>
                      </span>
                    </p>
                  </h2>
                </div>
              </div>
              <div
                style={{
                  width: "70%",
                  display: "inline-block",
                  textAlign: "left",
                  // verticalAlign: "text-top",
                  // paddingTop: "5px",
                }}
              >
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "50%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                    color: '#784aa3'
                  }}
                >
                  <b>Saccha Sathi ID</b>
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "5%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  :
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "45%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                  <b>{UserID}{" "}</b>
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "50%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                    color: '#784aa3'
                  }}
                >
                  <b>Name</b>
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "5%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  :
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "45%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                  <b>{UserName}{" "}</b>
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "50%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                    color: '#784aa3'
                  }}
                >
                  <b>Father/Husband Name</b>
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "5%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  :
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "45%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                  <b>{FatherName || "N/A"}{" "}</b>
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "50%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                    color: '#784aa3'
                  }}
                >
                  {" "}
                  <b> D.O.B</b>
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "5%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  :
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "45%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                  <b>{DOB || "N/A"}{" "}</b>
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "50%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                    color: '#784aa3'
                  }}
                >
                  {" "}
                  <b>Blood Group</b>
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "5%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  :
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "45%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    paddingTop: "5px",
                  }}
                >
                  {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                  <b> {BGroup || "N/A"}{" "}</b>
                </h2>
                {/* <h2 style={{ fontSize: "14px",marginBottom:"0px", width: "50%", display: "inline-block", }} > Address : </h2>
              <h2 style={{ fontSize: "14px",marginBottom:"0px", width: "50%", display: "inline-block", verticalAlign: "top", }}> {Address || "N/A"} </h2> */}
              </div>

              <div
                style={{
                  width: "30%",
                  display: "inline-block",
                  verticalAlign: "top",
                }}
              >
                {userdata["LogoPic"] ? (
                  <img
                    src={profileimg}
                    alt="icon"
                    style={{ width: "100px", height: "100px", border: '1px solid #784aa3' }}
                  />
                ) : (
                  <img
                    src="../assets/images/profileIcon.jpg"
                    alt="icon"
                    style={{ width: "100px", height: "100px", border: '1px solid #784aa3' }}
                  />
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "inline-block",
                  textAlign: "left",
                  // verticalAlign: "top",
                  paddingTop: "5px",
                }}
              >
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "35%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                    color: '#784aa3'
                  }}
                >
                  {" "}
                  <b>Address</b>
                  {/* <span style={{ paddingRight: "10px", float: "right" }}> :</span> */}
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "3.5%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                  }}
                >
                  :
                </h2>
                <h2
                  style={{
                    fontSize: "14px",
                    marginBottom: "0px",
                    width: "61%",
                    display: "inline-block",
                    verticalAlign: "text-top",
                  }}
                >
                  {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                  <b> {Address || "N/A"}{" "}</b>
                </h2>
              </div>
            </div>
            <div className="footer-bottom">
              <div><img src="assets/images/world-wide-web.png" /> www.canwinn.org</div>
              <div><img src="assets/images/phone-call.png" /> +91-9654000098</div>
            </div>
          </div>
          <div className="fllx">
            <div className="downloadbutton">
              <Button
                onClick={() => {
                  downloadImage();
                }}
                variant="contained"
              >
                {" "}
                Download Card Photo{" "}
              </Button>
            </div>
            <div className="downloadbutton">
              {showbtn === false ? (
                <Button
                  onClick={() => {
                    fun_DownloadCardPDF();
                  }}
                  variant="contained"
                >
                  {" "}
                  Generate PDF{" "}
                </Button>
              ) : showbtn && pdfUrl ? (
                <Link
                  to={pdfUrl}
                  download="Card.pdf"
                  target="_blank"
                  id="pdfclick1"
                >
                  Download PDF
                </Link>
              ) : (
                <Link to="" style={{ color: "red" }} disabled>
                  Generating PDF Please Wait....
                </Link>
              )}
            </div>
          </div>
          {/* <Button
            onClick={() => {
              fun_DownloadCardPDF();
            }}
            variant="contained"
          >
            {" "}
            Generate PDF New
          </Button> */}
          <div style={{ display: "none" }}>
            <div
              id="cart-detailpdf"
              style={{
                width: "100%",
                margin: "auto",
                display: "block",
                textAlign: "center",
              }}
            >
              <div className="MainCss2">
                <div
                  style={{
                    width: "60%",
                    textAlign: "center",
                    margin: "auto",
                    display: "block",
                    backgroundColor: "white",
                    paddingBottom: "30px",
                    paddingTop: "15px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    border: "1px solid #f2f2f2",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      // textAlign: "center !important",
                      // borderTop: "1px solid #000 !important",
                      //  marginRight: "6px",
                      // borderBottom: "1px solid #000 !important",
                      // borderRight: "1px solid #000 !important",
                      // borderLeft: "1px solid #000 !important",
                      // width: "72%",
                      // margin: "auto",
                      // display: "block",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <div className="LogoDIvText">
                      <img
                        src="https://dialerpstorage.blob.core.windows.net/40019/Actual_Common_IMG-40019-20240220012337.jpg"
                        className="Imagecss12"
                        alt="logo"
                        style={{
                          display: "inline-block",
                          verticalAlign: "baseline",
                          marginTop: "0px",
                          marginRight: "10px",
                          width: "65px",
                        }}
                      />
                      <h2
                        className="HeadingTitle"
                        style={{
                          width: "80%",
                          textAlign: "center",
                          display: "inline-block",
                          marginBottom: "0px",
                        }}
                      >
                        <span
                          style={{ color: "#7d54a2", display: "inline-block" }}
                        >
                          Can
                        </span>
                        <span
                          style={{
                            color: "#1db8ab",
                            marginRight: "5px",
                            marginBottom: "0px",
                            display: "inline-block",
                          }}
                        >
                          {" "}
                          winn
                        </span>
                        Foundation
                        <p style={{ color: "#000", lineHeight: "12px" }}>
                          <span
                            style={{
                              borderBottom: "2px solid black",
                              color: "#000",
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            <b>Saccha Sathi Card</b>
                          </span>
                        </p>
                      </h2>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "75%",
                      display: "inline-block",
                      margin: "auto",
                      textAlign: "left",
                      paddingLeft: "5%",
                      // verticalAlign: "text-top",
                      // paddingTop: "2px",
                    }}
                  >
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "50%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "0px",
                      }}
                    >
                      <b>Saccha Sathi ID</b>
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "5%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "0px",
                      }}
                    >
                      :
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "45%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "0px",
                      }}
                    >
                      {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                      <b>{UserID}{" "}</b>
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "50%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      <b>Name</b>
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "5%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      :
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "45%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                      <b>{UserName}{" "}</b>
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "50%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      <b>Father/Husband Name</b>
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "5%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      :
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "45%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                      <b>{FatherName || "N/A"}{" "}</b>
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "50%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      {" "}
                      <b>D.O.B</b>
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "5%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      :
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "45%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                      <b>{DOB || "N/A"}{" "}</b>
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "50%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      {" "}
                      <b>Blood Group</b>
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "5%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      :
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "45%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                        paddingTop: "2px",
                      }}
                    >
                      {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                      <b>{BGroup || "N/A"}{" "}</b>
                    </h2>

                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "50%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                      }}
                    >
                      {" "}
                      <b>Address</b>
                      {/* <span style={{ paddingRight: "10px", float: "right" }}> :</span> */}
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "5%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                      }}
                    >
                      :
                    </h2>
                    <h2
                      style={{
                        fontSize: "14px",
                        marginBottom: "0px",
                        width: "45%",
                        display: "inline-block",
                        verticalAlign: "text-top",
                      }}
                    >
                      {/* <span style={{ paddingRight: "10px", float: "left" }}> :</span> */}
                      <b>{Address || "N/A"}{" "}</b>
                    </h2>

                    {/* <h2 style={{ fontSize: "14px",marginBottom:"0px", width: "50%", display: "inline-block", }} > Address : </h2>
              <h2 style={{ fontSize: "14px",marginBottom:"0px", width: "50%", display: "inline-block", verticalAlign: "top", }}> {Address || "N/A"} </h2> */}
                  </div>

                  <div
                    style={{
                      width: "20%",
                      display: "inline-block",
                      verticalAlign: "text-top",
                      float: "right",
                    }}
                  >
                    {userdata["LogoPic"] ? (
                      <img
                        src={profileimg}
                        alt="icon"
                        style={{
                          width: "100px",
                          height: "100px",
                          paddingTop: "10px",
                        }}
                      />
                    ) : (
                      <img
                        src="../assets/images/profileIcon.jpg"
                        alt="icon"
                        style={{
                          width: "100px",
                          height: "100px",
                          paddingTop: "10px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </Container>
      </div>
    </div>
  );
}

export default IDCart;
